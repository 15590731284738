var menuOffset = 0;
var ytTopnavTimeout = 200;  // menu timeout
var ytTopnavOverTimeout = 300; // menu mouse enter timer
var ytHoveredMenu = null;
var ytMMonHost = true;

function ytTopnavLeave() {
    jQuery("#ytMegaMenu .ytA").removeClass("selected");
    jQuery("div.ytSubMenus").slideUp(100);
    jQuery("#ytMegaMenu .ytB").delay(100).hide();
}
function is_touch_device() {
	try {
		document.createEvent("TouchEvent");
		return true;
	} catch (e) {
		return false;
	}
}

jQuery(document).ready(function() {
	if ( !is_touch_device() ) {
		jQuery("#ytMegaMenu .ytA").mouseenter(function () {
			jQuery("#shopByChoices").removeClass('open');
			var hoverdId = jQuery("a",this).attr("href").replace("http://www.trophycentral.com/","").replace(".html","");
			clearTimeout(ytTopnavTimeout);
			clearTimeout(ytTopnavOverTimeout);
			ytHoveredMenu = this;
			ytTopnavOverTimeout = setTimeout(function () {
				clearTimeout(ytTopnavTimeout);
				clearTimeout(ytTopnavOverTimeout);
				if ( !jQuery("#"+hoverdId).is(":visible") ) {
					jQuery("div.ytSubMenus").slideUp(100);
					jQuery("div.ytSubMenus .ytB").delay(100).hide();
					jQuery("#ytMegaMenu .ytA").removeClass("selected");
					jQuery(ytHoveredMenu).addClass("selected");
					jQuery("#"+hoverdId).show();
					jQuery("div.ytSubMenus").slideDown(300);
				}
			}, 300);
		}).mouseleave(function () {
			clearTimeout(ytTopnavOverTimeout);
			ytTopnavTimeout = setTimeout(ytTopnavLeave, 200);
		});

		jQuery("div.ytSubMenus, div.ytSubMenus .ytB").mouseenter(function () {
			clearTimeout(ytTopnavTimeout);
		}).mouseleave(function () {
			ytTopnavTimeout = setTimeout(ytTopnavLeave, 200);
		});
	}
	else {
		jQuery("#ytMegaMenu .ytA").each(function() {
			jQuery(this).attr("mref", jQuery(this).attr("href") );
			jQuery(this).removeAttr("href");
		});
		jQuery("#ytMegaMenu .ytA").click(function(event) {
			event.preventDefault();
			var hoverdId = jQuery("a",this).attr("href").replace("http://www.trophycentral.com/","").replace(".html","");
			ytHoveredMenu = this;
			if ( jQuery("#"+hoverdId).is(":visible") ) {
				var actualLink = jQuery(this).attr("mref");
				location.href = actualLink;
			}
			else {
				jQuery("div.ytSubMenus .ytB").hide();
				jQuery("div.ytSubMenus").hide();
				jQuery("#ytMegaMenu .ytA").removeClass("selected");
				jQuery(ytHoveredMenu).addClass("selected");
				jQuery("div.ytSubMenus").slideDoen();
				jQuery("#"+hoverdId).show();
			}
			return (false);
		});
	}

	jQuery("a#shopByDropDown").click(function() {
		jQuery("#shopByChoices").toggleClass('open');
	});
});